import { useMemo } from "react";
import {useSlims} from "../hooks.js";
import  {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable
  } from "react-table";
  
import Link from "../widgets/Link.jsx";
import { HeaderWithModal } from "../utils.jsx"

const columnHelper = createColumnHelper()

const SlimsTile = ({protein_seq_id}) => {

    const {isReady, matches} = useSlims(protein_seq_id)

    const columns = useMemo(() => [
        columnHelper.accessor(
          row => row,
          {
            id: "ELM id",
            header: () => <span>ELM id</span>,
            cell: info => {
                const d = info.getValue()
                if (d) {
                  return <Link target={"_blank"}  href={`http://elm.eu.org/elms/${d.elm_id}`}  key={`tt-${d.elm_id}`}  text={d.elm_id}  extraClasses={"mx-1"} />
                }
            }
          }
        ),
        columnHelper.accessor(
            row => row,
            {
              id: "motif sequence",
              header: () => <span>motif sequence</span>,
              cell: info => {
                  const d = info.getValue()
                  return d.slim_seq
              }
            }
        ),
        columnHelper.accessor(
            row => row,
            {
              id: "description",
              header: () => <span>description</span>,
              cell: info => info.getValue().Description
            }
        ),
        columnHelper.accessor(
            row => row,
            {
              id: "position",
              header: () => <span>position</span>,
              cell: info => {
                const d = info.getValue()
                  return  `${d.slim_start} - ${d.slim_end}`
              }
            }
        ),
        columnHelper.accessor(
          row => row,
          {
            id: "score",
            header: () => <span>score</span>,
            cell: info => info.getValue().Probability.toExponential(2)
          }
      )
      ], [])

    const table = useReactTable({
        data: matches,
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    if(!isReady) {
        return <span>...loading</span>
    }

    if(!matches | matches.length==0)  {
      return (
        <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
            <div className="border-b width-full border-slate-200">
                <p className="font-semibold">Predicted Short Linear Motifs by ELM</p>
            </div>
            <div>no detected SLIMs in this sequence</div>
        </div>
      );
    }

    return (
      <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
          <div className="border-b width-full border-slate-200">
            <HeaderWithModal title="Predicted Short Linear Motifs by ELM">
            <p>SLIM Prediction by ELM. 
            <a className="cursor-pointer hover:underline text-blue-600" href='/documentation'> See the documentation</a> for more details on the method.</p>
            <p>ELM developers warn against a probable high false positive rate. Context is important when interpreting the result. <a className="cursor-pointer hover:underline text-blue-600" href='http://elm.eu.org/help.html'>See the ELM help page for more details.</a></p>
            <table className="border-collapse border-t border-slate-400" >
              <tr className="border-t border-slate-300">
                <th className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Column</th>
                <th className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Description</th>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">ELM ID</td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">The ID of the ELM class detected, click to view details on ELM website.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Motif Sequence </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">The motif detected on the protein that fits the regular expression.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Description </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">A short description of the ELM class.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Position </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">The start and end positions of the motif on the amino acid sequence.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Score </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Probability of finding the motif on a random amino acid sequence. A lower score means a lower chance of false positive.  <a className="cursor-pointer hover:underline text-blue-600" href='http://elm.eu.org/help.html#filter_probability'>See the ELM help page for more details.</a></td>
              </tr>
            </table>
            <p></p>

            </HeaderWithModal>
          </div>
        <table className={"w-full text-sm text-left text-gray-500"}>
          <thead className={"text-xs text-gray-700 uppercase bg-gray-50"}>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} className={"px-4 py-1"}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className={"bg-white border-b hover:bg-gray-50"}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className={"px-4 py-1"}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
}

export default SlimsTile