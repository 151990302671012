import SiteHeader from "../components/SiteHeader.jsx";
import SiteFooter from "../components/SiteFooter.jsx";

const decoratedLink = (uri, label) => {
    return <a href={uri} className="underline hover:font-semibold">{label}</a>
}

export default () => <div className="flex flex-col h-screen justify-between">
    <SiteHeader/>

    <div className="grid grid-row-3 place-content-center gap-16">

        <div className="text-center">
            <h1 className="text-3xl font-semibold mb-5 text-blue-800">Publications</h1>
            <h3 className="text-base mb-2">If you've used OpenProt, please cite our latest publication:</h3>
                <div className="w-3/4 mx-auto">
                    <p className="text-base">
                        <span className="font-semibold"> OpenProt 2.0 builds a path to the functional characterization of alternative proteins.</span> Leblanc S, Yala F, Provencher N, Lucier JF, Levesque M, Lapointe X, Jacques JF, Fournier I, Salzet M, Ouangraoua A, Scott MS, Boisvert FM, Brunet MA, Roucou X (2024). 
                         Nucleic Acids Res. <a className="underline hover:font-semibold" href="https://doi.org/10.1093/nar/gkad1050" target="blank">https://doi.org/10.1093/nar/gkad1050</a>
                    </p>
                </div>
        </div>

        <div className="text-center p-0.1 -mb-6">
            <h3 className="font-semibold" >Other Publications</h3>
        </div>
        <div className="w-3/4 mx-auto">
            <div className="mb-3"><span className="font-semibold">OpenCustomDB: Integration of Unannotated Open Reading Frames and Genetic Variants to Generate More Comprehensive Customized Protein Databases. </span> 
            Guilloy N, Brunet MA, Leblanc S, Jacques JF, Hardy MP, Ehx G, Lanoix J, Thibault P, Perreault C, Roucou X. J Proteome Res. 2023 May 5;22(5):1492-1500. 
            doi: <a className="underline hover:font-semibold" href="https://pubs.acs.org/doi/10.1021/acs.jproteome.3c00054"> 10.1021/acs.jproteome.3c00054</a></div>
            
            <div className="mb-3"><span className="font-semibold">Newfound Coding Potential of Transcripts Unveils Missing Members of Human Protein Communities. </span> 
            Leblanc S, Brunet MA, Jacques JF, Lekehal AM, Duclos A, Tremblay A, Bruggeman-Gascon A, Samandi S, Brunelle M, Cohen AA, Scott MS, Roucou X. Genomics Proteomics Bioinformatics. 2022 Sep 29:S1672-0229(22)00124-3.  
            doi: <a className="underline hover:font-semibold" href="https://academic.oup.com/nar/article/49/D1/D380/5976898?login=true"> 10.1016/j.gpb.2022.09.008</a></div>

            <div className="mb-3"><span className="font-semibold">OpenVar: functional annotation of variants in non-canonical open reading frames. </span> 
            Brunet MA, Leblanc S, Roucou X. Cell Biosci. 2022 Aug 14;12(1):130. 
            doi: <a className="underline hover:font-semibold" href="https://cellandbioscience.biomedcentral.com/articles/10.1186/s13578-022-00871-x">10.1186/s13578-022-00871-x</a></div>

            <div className="mb-3"><span className="font-semibold">OpenProt 2021: deeper functional annotation of the coding potential of eukaryotic genomes. </span> 
            Brunet MA, Lucier JF, Levesque M, Leblanc S, Jacques JF, Al-Saedi HRH, Guilloy N, Grenier F, Avino M, Fournier I, Salzet M, Ouangraoua A, Scott MS, Boisvert FM, Roucou X. Nucleic Acids Res. 2021 Jan 8;49(D1):D380-D388. 
            <a className="underline hover:font-semibold" href="https://doi.org/10.1093/nar/gkaa1036"> https://doi.org/10.1093/nar/gkaa1036</a></div>

            <div className="mb-3"><span className="font-semibold">How to Illuminate the Dark Proteome Using the Multi-omic OpenProt Resource. </span> 
            Brunet MA, Lekehal AM, Roucou X. Curr Protoc Bioinformatics. 2020 Sep;71(1):e103.  
            doi: <a className="underline hover:font-semibold" href="https://currentprotocols.onlinelibrary.wiley.com/doi/10.1002/cpbi.103"> 10.1002/cpbi.103</a></div>

            <div className="mb-3"><span className="font-semibold">OpenProt: a more comprehensive guide to explore eukaryotic coding potential and proteomes. </span> 
            Brunet MA, Brunelle M, Lucier JF, Delcourt V, Levesque M, Grenier F, Samandi S, Leblanc S, Aguilar JD, Dufour P, Jacques JF, Fournier I, Ouangraoua A, Scott MS, Boisvert FM, Roucou X. Nucleic Acids Res. 2019 Jan 8;47(D1):D403-D410. 
            doi: <a className="underline hover:font-semibold" href="https://academic.oup.com/nar/article/47/D1/D403/5123790?login=true"> 10.1093/nar/gky936</a></div>
        </div>

    </div>
    <SiteFooter/>
</div>