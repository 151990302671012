import { useMemo } from "react"
import {
    useReactTable,
    getCoreRowModel,
    createColumnHelper,
    flexRender
} from 'react-table'

import {useConservation} from "../hooks.js"
import {SimpleDictTable} from "../widgets/SimpleTable.jsx"
import { downloadFile, HeaderWithModal } from "../utils.jsx"

const columnHelper = createColumnHelper()

export default ({species_sn, protein_seq_id}) => {

    const {
        orthologs,
        paralogs
    } = useConservation({
        species_sn,
        protein_seq_id,
        renderSpeciesCell: species => species.sc
    })


    const blastColumnTitles = {
        sseq_a: "subject",
        pident: "pident",
        length: "length",
        mismatch: "mismatch",
        gapopen: "gapopen",
        qstart: "qstart",
        qend: "qend",
        sstart: "sstart",
        send: "send",
        evalue: "evalue",
        bitscore: "bitscore",
        qcovs: "qcovs",
        qlen: "qlen",
        slen: "slen"
    }


    const paralogColumTitles = {
        ...blastColumnTitles,
        is_isoform: "isoform"
    }


    const columns= useMemo(() =>[
          columnHelper.accessor(
              row => row[0],
              {
                header: 'Species',
                cell: info => {
                    const o = info.getValue()
                    if(o === null) {
                        return null
                    }

                    return o.content
                }
              }
          ),
            ...["subject",
                "pident",
                "length",
                "mismatch",
                "gapopen",
                "qstart",
                "qend",
                "sstart",
                "send",
                "evalue",
                "bitscore",
                "qcovs",
                "qlen",
                "slen"
            ].map(
              (title, idx) =>
                columnHelper.accessor(
                  row => row[idx + 1],
                  {
                    header: title,
                    cell: info => info.getValue().content
                  }
                )
          )
        ],[])

    const orthologTable = useReactTable({
        data: orthologs,
        columns,
        getCoreRowModel: getCoreRowModel()
    })
    
    const downloadOrthologs = e => {
      
      e.preventDefault()

      let headers = ["species,subject,pident,length,mismatch,gapopen,qstart,qend,sstart,send,evalue,bitscore,qcovs,qlen,slen"]
    
      let rows = orthologs.reduce((acc, orth) => {
        const u = orth.map((x, idx)=> x !== null ? x.content : acc[acc.length-1][0])
        acc.push(u)
        return acc
      }, [])
    
      downloadFile({
        data: [...headers, ...rows.map(r => r.join(','))].join('\n'),
        fileName: `orthologs_${protein_seq_id}.csv`,
        fileType: 'text/csv',
      })
    }

    const downloadParalogs = e => {
      
      e.preventDefault()

      let headers = ["subject,pident,length,mismatch,gapopen,qstart,qend,sstart,send,evalue,bitscore,qcovs,qlen,slen,isoform"]
      const columns = ['sseq_a','pident','length','mismatch','gapopen','qstart','qend','sstart','send','evalue','bitscore','qcovs','qlen','slen','is_isoform']
      console.log(paralogs)
      console.log(paralogColumTitles)
      const rows = paralogs.map(p => columns.map(t => p[t]))
      downloadFile({
        data: [...headers, ...rows.map(r => r.join(','))].join('\n'),
        fileName: `paralogs_${protein_seq_id}.csv`,
        fileType: 'text/csv',
      })
    }

    const renderOrthologTable = () =>  <table className="w-full text-sm text-left text-gray-500">
        <thead>
          {orthologTable.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                      key={header.id}
                      className="border border-slate-300"
                      colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {orthologTable.getRowModel().rows.map(row => {
            return (
              <tr
                  key={row.id}
              >
                    {
                        row.getVisibleCells().map(cell => {
                            const ctx = cell.getContext()
                            const v = ctx.getValue()
                            if(v === null) {
                                return null
                            }
                            if(!v) {
                                return null
                            }
                            return (
                                <td
                                    rowSpan={v.rowSpan}
                                    className="border border-slate-300"
                                    key={cell.id}
                                >
                                    {flexRender(cell.column.columnDef.cell, ctx)}
                                </td>
                            )
                        })
                    }
              </tr>
            )
          })}
        </tbody>
      </table>

    return <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
        <div className="border-b width-full border-slate-200">
          <HeaderWithModal title="Conservation Analysis">
            <p>These tables contain the results of a phylogenetic conservation analyses of the protein sequence. 
            <a className="cursor-pointer hover:underline text-blue-600" href='/documentation'> See the documentation</a> for more details on the method.</p>
            <p>Column description:</p>
            <table className="border-collapse border-t border-slate-400" >
              <tr className="border-t border-slate-300">
                <th className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Column</th>
                <th className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Description</th>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">pident</td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Percentage of identical matches</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">mismatch </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Number of mismatches</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">gapopen </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Number of gap openings</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">qstart </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Start of alignment in query</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">qend </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">End of alignment in query</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">sstart </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">End of alignment in subject</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">send </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Percentage of identical matches</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">qcovs </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Query Coverage Per Subject</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">qlen </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">query length</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">slen </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">subject length</td>
              </tr>
            </table>
          </HeaderWithModal>
        </div>
        <div className="relative w-full">
          <h2 className="font-semibold mt-3">Orthologs</h2>
          <button className='right-0 top-0 absolute font-medium text-blue-600 dark:text-blue-500 hover:underline' type='button' onClick={downloadOrthologs}>download</button>
        </div>

        {renderOrthologTable()}
        <div className="relative w-full">
          <h2 className="font-semibold mt-3">Paralogs</h2>
          <button className='right-0 top-0 absolute font-medium text-blue-600 dark:text-blue-500 hover:underline' type='button' onClick={downloadParalogs}>download</button>
        </div>
        <SimpleDictTable colDefs={paralogColumTitles} rows={paralogs} />
    </div>
}


