import { useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  flexRender,
  createColumnHelper
} from 'react-table'
import Link from "../widgets/Link.jsx";
import {useFetchRiboseqData} from "../hooks.js";
import { HeaderWithModal } from "../utils.jsx"

const columnHelper = createColumnHelper()

//good test:  ENSP00000367263.4, http://localhost:3000/proteins/HS/196752

const RiboseqTable = ({species_sn, protein_seq_id}) => {

    const {isReady, rows} = useFetchRiboseqData({
        species_sn,
        protein_seq_id,
        renderStudy: study =>
            <div>
               <Link
                   href={study.url}
                   target={"_blank"}
                   text={study.study_name}
               />
               {study.citation && <div>PMID: {study.citation}</div>}
           </div>,
        renderSamples: samples => samples.map(
            sample =>
                <div
                    key={`${sample.accession}`}
                >
                    {sample.accession}: {sample.value}
                </div>
        )
    })

    const columns= useMemo(
        () => [
          columnHelper.accessor(
              row => row[0],
              {
                header: 'study',
                cell: info => {
                    const o = info.getValue()
                    if(o === null) {
                        return null
                    }

                    return o.content
                }
              }
          ),
          ...["annotation source",
              "codon",
              "type",
              "start score",
              "range score",
              "p value",
              "samples",
              "total readcount",
              "transcript,protein",
              "overlap %"
          ].map(
              (title, idx) =>
                columnHelper.accessor(
                  row => row[idx + 1],
                  {
                    header: title,
                    cell: info => info.getValue().content
                  }
                )
          )
        ],
        []
    )

    const table = useReactTable({
        data: rows,
        columns,
        //onGroupingChange: () => {},
        //getExpandedRowModel: getExpandedRowModel(),
        //getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel()
    })

    if(!isReady) {
        return <div>loading...</div>
    }

    return <table className="w-4/5 text-sm text-left text-gray-500">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                      key={header.id}
                      className="border border-slate-300"
                      colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr
                  key={row.id}
              >
                    {
                        row.getVisibleCells().map(cell => {
                            const ctx = cell.getContext()
                            const v = ctx.getValue()
                            if(v === null) {
                                return null
                            }
                            return (
                                <td
                                    rowSpan={v.rowSpan}
                                    className="border border-slate-300"
                                    key={cell.id}
                                >
                                    {flexRender(cell.column.columnDef.cell, ctx)}
                                </td>
                            )
                        })
                    }
              </tr>
            )
          })}
        </tbody>
      </table>
}


export default ({species_sn, protein_seq_id}) => {


    return <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
          <div className="border-b width-full border-slate-200 mb-4">
            <HeaderWithModal title="Ribosome Profiling">
            <p>See the results from the re-analysis of public ribosome profiling data using the OpenProt 2.0 annotations and the PRICE algorithm.
               <a> See the documentation</a> for more details on the method.</p>
            <p>Column description:</p>
            <table className="border-collapse border-t border-slate-400">
              <tr className="border-t border-slate-300">
                <th>Column</th>
                <th>Description</th>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Study</td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Name of the study that originated the data.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">annotation source</td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Annotation from which the transcript originates.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">codon </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Codon aligned with ribosome protected fragments.</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">type </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">
                  inferred ORF type:
                  <ul className='ml-4'>
                    <li >CDS: ORF is exactly as in the annotation</li>
                    <li>Ext: ORF contains a CDS, ending at its stop codon</li>
                    <li>Trunc: ORF is contained in a CDS, ending at its stop codon</li>
                    <li>Variant: ORF ends at a CDS stop codon, but is neither Ext nor Trunc</li>
                    <li>uoORF: ORF starts in 5'-UTR, ends within a CDS</li>
                    <li>uORF: ORF starts and ends in 5'-UTR</li>
                    <li>iORF: ORF is contained within a CDS</li>
                    <li>dORF: ORF ends in 3'-UTR</li>
                    <li>ncORF: ORF is located on non-coding transcript</li>
                    <li>intronORF: ORF is located in an intron</li>
                    <li>orphan: everything else</li>
                  </ul>
                </td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">start score </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Start of alignment in query</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">range score </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">End of alignment in query</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">p value </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">End of alignment in subject</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">samples </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Percentage of identical matches</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">total readcount </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">Query Coverage Per Subject</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">transcript, protein </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">query length</td>
              </tr>
              <tr className="border-t border-slate-300">
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">% overlap </td>
                <td className="pr-10 py-2 pl-1 rounded-lg text-gray-700">subject length</td>
              </tr>
            </table>
          </HeaderWithModal>
          </div>
        <RiboseqTable species_sn={species_sn} protein_seq_id={protein_seq_id}/>
    </div>
}
