import { useState } from "react";

export const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
  
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

export const tooltip_ = (text, tooltip=null) =>
  <div className="group">
  <div>{text}</div>
  {tooltip ? <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-400 text-white p-1 rounded absolute mb-1 text-xs whitespace-nowrap">
            {tooltip}
            </div> : null}
  </div>

export  const decoratedlist = (label, data, link=null, hover=null) =>
  <div className="flex">

      <div className="group relative inline-block m-1 w-1/3 pr-2 text-right border-r-2 border-slate-400 font-bold cursor-default">
          <div>{label}</div>

          {hover ? <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-400 text-white p-1 rounded absolute bottom-full mb-1 text-xs whitespace-nowrap">
          {hover}
          </div> : null}
      </div>

      <div className="m-auto ml-2">{data} {link}</div>

  </div>

export  const horizontalList = (label, data, link=null, hover=null) =>
  <div className="flex flex-col w-fit">
      <div className="group relative inline-block m-1 pr-2 text-center border-b-2 border-slate-400 cursor-default">
          <div>{label}</div>
          {hover ? <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-400 text-white p-1 rounded absolute bottom-full mb-1 text-xs whitespace-nowrap">
          {hover}
          </div> : null}
      </div>
      <div className="text-center">{data} {link}</div>
  </div>


export const HeaderWithModal = ({title, children}) => {
  const [visibleModal, setVisibleModal] = useState(false)

  return <>
    <a className="font-semibold cursor-pointer" onClick={() => setVisibleModal(true)} >{title}<sup class="font-features sups">i</sup></a>
  {visibleModal && <Modal title={title} onClose={() => setVisibleModal(false)}> {children} </Modal>}
  </>
}


export const Modal = ({title, onClose, children}) => {
  return <div className="fixed inset-0 z-50 p-4 max-h-full flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow max-w-screen">
        <div className="flex items-center justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-medium text-gray-900">{title}</h3>
          <button onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="extralarge-modal">
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="p-6 space-y-6 overflow-y-scroll max-h-96">{children}</div>
      </div>
  </div>
}
  

  

