

export default ({text, href, onClick, k, extraClasses, target}) =>{
    var linkColor = ""
    switch (true) {
        case (text.includes("IP_")):
            linkColor = 'text-[#CB3D58]'
            break
        case (text.includes("II_")):
            linkColor = 'text-[#E0B64C]'
            break
        default:
            linkColor = 'text-[#3C47C7]'
    }
    
    return <a href={href}
       target={target}
       onClick={onClick}
       className={`font-medium ${linkColor} hover:underline ${extraClasses || ''}`}>
        {text}
    </a>
}

