// import { useState, useRef, useEffect } from "react"
// import {useIdrs} from "../hooks.js"
// import { select } from 'd3-selection'
// import { axisBottom, axisLeft } from 'd3-axis'
// import { scaleLinear, scaleSequential } from 'd3-scale'
// import { zoom } from 'd3-zoom'
// import { line } from 'd3-shape'
// import { interpolateViridis } from 'd3-scale-chromatic'
// import { HeaderWithModal } from "../utils.jsx"

// const IdrViewer = ({species_sn, protein_seq_id}) => {
//     const idrs = useIdrs(protein_seq_id)
//     const svgRef = useRef();

//     useEffect(() => {
//         const svg = select(svgRef.current);

//         const fldpnn_scores = idrs.res.map(val => parseFloat(val["IUPRED2"])).filter(val => !Number.isNaN(val))
//         //const disorder_blocks = fldpnn_scores.map((val, idx) => (val > 0.3) ? val : NaN).filter(val => !Number.isNaN(val))
//         const disorder_blocks_idx = fldpnn_scores.map((val, idx) => (val > 0.3) ? idx : NaN).filter(val => !Number.isNaN(val))
//         const disorder_blocks_rdp_p = idrs.res.map(val => parseFloat(val["rdp_p"])).filter(val => !Number.isNaN(val))
//         const disorder_blocks_rdp_d = idrs.res.map(val => parseFloat(val["rdp_d"])).filter(val => !Number.isNaN(val))
//         const disorder_blocks_rdp_r = idrs.res.map(val => parseFloat(val["rdp_r"])).filter(val => !Number.isNaN(val))
//         const disorder_blocks_dfl = idrs.res.map(val => parseFloat(val["dfl"])).filter(val => !Number.isNaN(val))

//         const block_width = Math.ceil((1000-100)/fldpnn_scores.length)

//         const xScale = scaleLinear().domain([0, fldpnn_scores.length]).range([100, 1000]);
//         const yScale = scaleLinear().domain([0, 1]).range([130, 10]);
//         const xAxis = axisBottom(xScale);
//         const yAxis = axisLeft(yScale);

//         const colorScale = scaleSequential()
//             .domain([1,0])
//             .interpolator(interpolateViridis)
        
//         const fldpnn_line = line()
//             .x((val, idx) => xScale(idx))
//             .y(val => yScale(val));

//         const threshold_line = line()
//             .x((val, idx) => xScale(idx))
//             .y(yScale(0.3))

//         svg.select(".x-axis").style("transform", "translateY(130px)").call(xAxis);
//         svg.select(".y-axis").style("transform", "translateX(100px)").call(yAxis);

//         svg
//             .selectAll(".fldpnn-scores")
//             .data([fldpnn_scores])
//             .join("path")
//             .attr("class", "fldpnn-scores")
//             .attr("d", value => fldpnn_line(value))
//             .attr("fill", "none")
//             .attr("stroke", "black")

//         svg
//             .selectAll(".threshold-line")
//             .data([fldpnn_scores])
//             .join("path")
//             .attr("class", "threshold-line")
//             .attr("d", value => threshold_line(value))
//             .attr("fill", "none")
//             .attr("stroke", "black")
//             .style("stroke-dasharray", ("3, 3"))
        
//         svg
//             .selectAll(".line_legend")
//             .data(['fldpnn_score'])
//             .join("path")
//             .attr("class", "threshold-line")
//             .attr("d", 'M900,8L920,8')
//             .attr("stroke", "black")
//         svg
//             .selectAll(".line_legend")
//             .data(['fldpnn_score'])
//             .join("path")
//             .attr("class", "threshold-line")
//             .attr("d", 'M900,23L920,23')
//             .attr("stroke", "black")
//             .style("stroke-dasharray", ("3, 3"))
//         svg
//             .selectAll(".line_legend_labels")
//             .data(['flDPnn score', 'threshold'])
//             .join("text")
//             .attr("x",  925)
//             .attr("y", (v, idx) => [5, 20][idx]+6)
//             .style("font-size", 12)
//             .text(value => value)
//         svg
//             .selectAll(".xaxis_label")
//             .data(['residue position'])
//             .join("text")
//             .attr("x",  5)
//             .attr("y", 148)
//             .style("font-size", 12)
//             .text(value => value)

//         svg
//             .selectAll(".idr-blocks")
//             .data(disorder_blocks_idx)
//             .join("rect")
//             .attr("x", value => xScale(value)-block_width/2)
//             .attr("y", 170)
//             .attr("width", block_width)
//             .attr("height", 20)
//             .attr("fill", "black")

//         svg
//             .selectAll(".drp_p_blocks")
//             .data(disorder_blocks_idx)
//             .join("rect")
//             .attr("x", value => xScale(value)-block_width/2)
//             .attr("y", 170+30)
//             .attr("width", block_width)
//             .attr("height", 20)
//             .attr("fill", (val, idx) => colorScale(disorder_blocks_rdp_p[idx]))
        
//         svg
//             .selectAll(".drp_d_blocks")
//             .data(disorder_blocks_idx)
//             .join("rect")
//             .attr("x", value => xScale(value)-block_width/2)
//             .attr("y", 170+30+25)
//             .attr("width", block_width)
//             .attr("height", 20)
//             .attr("fill", (val, idx) => colorScale(disorder_blocks_rdp_d[idx]))
        
//         svg
//             .selectAll(".drp_r_blocks")
//             .data(disorder_blocks_idx)
//             .join("rect")
//             .attr("x", value => xScale(value)-block_width/2)
//             .attr("y", 170+30+25*2)
//             .attr("width", block_width)
//             .attr("height", 20)
//             .attr("fill", (val, idx) => colorScale(disorder_blocks_rdp_r[idx]))

//         svg
//             .selectAll(".dfl_blocks")
//             .data(disorder_blocks_idx)
//             .join("rect")
//             .attr("x", value => xScale(value)-block_width/2)
//             .attr("y", 170+30+25*3)
//             .attr("width", block_width)
//             .attr("height", 20)
//             .attr("fill", (val, idx) => colorScale(disorder_blocks_dfl[idx]))
        
//         svg
//             .selectAll(".yaxis-label")
//             .data(['probability of disorder'])
//             .join("text")
//             .attr("x",  60)
//             .attr("y", 130)
//             .style("font-size", 12)
//             .text(value => value)
//             .attr("transform", "rotate(-90,60,130)")

//         svg
//             .selectAll(".block_titles")
//             .data(['IDRs'])
//             .join("text")
//             .attr("x",  0)
//             .attr("y", 170+15)
//             .style("font-size", 12)
//             .text(value => value)

//         svg
//             .selectAll(".block_titles")
//             .data(['protein binding', 'DNA binding', 'RNA binding', 'linker'])
//             .join("text")
//             .attr("x",  0)
//             .attr("y", (value, idx) => 170+30+15+25*idx)
//             .style("font-size", 12)
//             .text(value => value)
//         svg
//             .selectAll(".block_titles")
//             .data(['probability'])
//             .join("text")
//             .attr("x",  920)
//             .attr("y", 170+48+25*5)
//             .style("font-size", 12)
//             .text(value => value)

//         const legend_points = [...Array(10).keys()].map(d=> ({color:colorScale(d/10), value:d}))
//         const extent = [0, 9]

//         const defs = svg.append("defs")
//         var linearGradient = defs.append("linearGradient").attr("id", "viridisGradient")
//         linearGradient.selectAll("stop")
//             .data(legend_points)
//             .enter().append("stop")
//             .attr("offset", d => ((d.value - extent[0]) / (extent[1] - extent[0]) * 100) + "%")
//             .attr("stop-color", d => d.color);

//         const g = svg.append("g").attr("transform", "translate(910,300)");
//         g
//             .append("rect")
//             .attr("width", 80)
//             .attr("height", 15)
//             .style("fill", "url(#viridisGradient)");
//         const legendTicks = [0.0, 0.5, 1.0]
//         const legendScale = scaleLinear().domain([0, 1]).range([0, 80]);
//         const legendAxis = axisBottom(legendScale)
//             .tickSize(20)
//             .tickValues(legendTicks)
//         g
//             .append("g")
//             .call(legendAxis)
//             .select(".domain")
//             .remove()


//     }, [idrs.res]);

//     if (species_sn != 'HS') {
//         return (
//             <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
//             <div className="border-b width-full border-slate-200">
//                 <p className="font-semibold">Intrinsically Disordered Regions predicted by flDPnn</p>
//             </div>
//             <div>Anlysis in progress... Results will be available soon.</div>
//             </div>
//             );
//     }

//     if (idrs.res.length==0) {
//         return (
//             <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
//             <div className="border-b width-full border-slate-200">
//                 <p className="font-semibold">Intrinsically Disordered Regions predicted by flDPnn</p>
//             </div>
//             <div>IDR prediction not available</div>          
//             </div>
//             );
//     }

//     return (
//         <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
//             <div className="border-b width-full border-slate-200">
//                 <HeaderWithModal title="Intrinsically Disordered Regions">
                    
//                 </HeaderWithModal>
//             </div>
//             <div className="flex justify-center mt-3">    
//                 <p>We are updating our IDR prediction toolset. Computations are currently under way, come back soon!</p>
//             </div>            
//         </div>
//         );
// }

// export default IdrViewer

////////////////////////////////////////////////////////////////////

// import { useRef, useEffect } from "react";
// import { useIdrs } from "../hooks.js";
// import { select } from 'd3-selection';
// import { axisBottom, axisLeft } from 'd3-axis';
// import { scaleLinear } from 'd3-scale';
// import { line } from 'd3-shape';
// import { HeaderWithModal } from "../utils.jsx";

// const IdrViewer = ({species_sn, protein_seq_id}) => {
//     const idrs = useIdrs(protein_seq_id);
//     const svgRef = useRef();

//     useEffect(() => {
//         if (idrs.res.length > 0) {
//             const svg = select(svgRef.current);
//             const iupred_scores = idrs.res.map(val => parseFloat(val.IUPRED2)).filter(val => !Number.isNaN(val));

//             const xScale = scaleLinear().domain([0, iupred_scores.length]).range([100, 1000]);
//             const yScale = scaleLinear().domain([0, 1]).range([130, 10]);

//             const xAxis = axisBottom(xScale);
//             const yAxis = axisLeft(yScale);

//             svg.select(".x-axis").style("transform", "translateY(130px)").call(xAxis);
//             svg.select(".y-axis").style("transform", "translateX(100px)").call(yAxis);

//             const scoreLine = line()
//                 .x((val, idx) => xScale(idx))
//                 .y(val => yScale(val));

//             svg.selectAll(".score-line")
//                 .data([iupred_scores])
//                 .join("path")
//                 .attr("class", "score-line")
//                 .attr("d", scoreLine)
//                 .attr("fill", "none")
//                 .attr("stroke", "black");

//             // Ajout de la ligne hachée pour le seuil IDR à 0.5
//             svg.append("line")
//                 .attr("x1", xScale(0))
//                 .attr("x2", xScale(iupred_scores.length))
//                 .attr("y1", yScale(0.5))
//                 .attr("y2", yScale(0.5))
//                 .attr("stroke", "red")
//                 .attr("stroke-width", 2)
//                 .attr("stroke-dasharray", "5,5");  // Style haché
//         }
//     }, [idrs.res]);

//     // Autres éléments de rendu...
//     return (
//         <div>
//             {/* Contenu conditionnel basé sur species_sn et idrs.res... */}
//             <svg ref={svgRef} width="1100" height="200">
//                 <g className="x-axis"></g>
//                 <g className="y-axis"></g>
//             </svg>
//         </div>
//     );
    
// }


// export default IdrViewer;


////////////////////////////////////////////////////////////////////////////////////////////

import { useRef, useEffect } from "react";
import { useIdrs } from "../hooks.js";
import { select } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { HeaderWithModal } from "../utils.jsx";

const IdrViewer = ({ species_sn, protein_seq_id }) => {
    const idrs = useIdrs(protein_seq_id);
    const svgRef = useRef();

    useEffect(() => {
        if (idrs && idrs.res && idrs.res.length > 0) {
            const svg = select(svgRef.current);
            const iupred_scores = idrs.res.map(val => parseFloat(val.IUPRED2)).filter(val => !Number.isNaN(val));

            const xScale = scaleLinear().domain([0, iupred_scores.length]).range([100, 1000]);
            const yScale = scaleLinear().domain([0, 1]).range([130, 10]);

            const xAxis = axisBottom(xScale);
            const yAxis = axisLeft(yScale);

            const xAxisGroup = svg.append("g")
                .attr("class", "x-axis")
                .attr("transform", "translate(0,130)")
                .call(xAxis);
            xAxisGroup.append("text")
                .attr("class", "axis-label")
                .attr("x", 550) 
                .attr("y", 30) 
                .style("text-anchor", "middle")
                .text("Residue Position");

            const yAxisGroup = svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", "translate(100,0)")
                .call(yAxis);
            yAxisGroup.append("text")
                .attr("class", "axis-label")
                .attr("transform", "rotate(-90)")
                .attr("y", -50) 
                .attr("x", -70) 
                .style("text-anchor", "middle")
                .text("Prediction Score of Disorder");

            const scoreLine = line()
                .x((val, idx) => xScale(idx))
                .y(val => yScale(val));

            svg.selectAll(".score-line")
                .data([iupred_scores])
                .join("path")
                .attr("class", "score-line")
                .attr("d", scoreLine)
                .attr("fill", "none")
                .attr("stroke", "black");

            svg.append("line")
                .attr("x1", xScale(0))
                .attr("x2", xScale(iupred_scores.length))
                .attr("y1", yScale(0.5))
                .attr("y2", yScale(0.5))
                .attr("stroke", "red")
                .attr("stroke-width", 2)
                .attr("stroke-dasharray", "5,5");
            svg
                .selectAll(".xaxis_label")
                .data(['residue position'])
                .join("text")
                .attr("x",  490)
                .attr("y", 190)
                .style("font-size", 12)
                .text(value => value)
            svg
                .selectAll(".yaxis-label")
                .data(['probability of disorder'])
                .join("text")
                .attr("x",  60)
                .attr("y", 130)
                .style("font-size", 12)
                .text(value => value)
                .attr("transform", "rotate(-90,60,130)")
        }
    }, [idrs.res]);

    return (
        <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
            <div className="border-b width-full border-slate-200">
                <HeaderWithModal title="Intrinsically Disordered Regions">
                    <p className="font-semibold">
                        {idrs && idrs.res && idrs.res.length > 0 ? "Intrinsically Disordered Regions predicted by IUPRED3" :
                        "IDR prediction not available"}
                    </p>
                </HeaderWithModal>
            </div>
            <div className="flex justify-center mt-3">
                {idrs && idrs.res && idrs.res.length > 0 ? (
                    <svg ref={svgRef} width="1100" height="200">
                        <g className="x-axis"></g>
                        <g className="y-axis"></g>
                    </svg>
                ) : (
                    <div className="text-center">IDR prediction not available</div>
                )}
            </div>
        </div>
    );
}

export default IdrViewer;



