import {use3DMolViewer} from "../hooks.js";
import {useAlphaOmega} from "../hooks.js";
import {getPdbUrl} from "../rest.js";
import { HeaderWithModal } from "../utils.jsx"

const PdbViewer = ({protein_seq_id}) => {

    const {viewerElementRef, pdbNotFound} = use3DMolViewer({protein_seq_id})
    const {isReady, alphaOrOmega} = useAlphaOmega(protein_seq_id)
    const pdbUrl = getPdbUrl(protein_seq_id)
    console.log(viewerElementRef, pdbNotFound)
    return (
        <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
            {
                pdbNotFound ?
                    <div>No PDB for protein {protein_seq_id}</div> :
                    <div className="border-b width-full border-slate-200">
          <HeaderWithModal title="Structure Prediction">
            <p>Structure predictions by Alphafold and Omegafold. 
            <a className="cursor-pointer hover:underline text-blue-600" href='/documentation'> See the documentation</a> for more details on the method.</p>
               <p>The structure is predicted either by AlphaFold if the multiple sequence alignment (MSA) is greater than 30. Or by OmegaFold if the MSA is less than 30. The RefProts structures have been downloaded from the <a className="cursor-pointer hover:underline text-blue-600" href='https://alphafold.ebi.ac.uk/' > AlphaFold DB</a></p>
               <p>plDDT score : The plDDT score is a confidence score that provides information on the predicted structure. It ranges from 0-100 %.. The closer the plDDT is to 100, the more confident the predicted structure is.</p>
          </HeaderWithModal>
                    </div>
            }
            
            <div className="grid grid-cols-4">
                { 
                    pdbNotFound ?
                    "":
                <div className="m-auto">
                    <p>predicted by {alphaOrOmega}</p>
                    <p><a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href={pdbUrl}>download pdb</a></p>
                    <img src="/img/plddt_legend.svg" width="120" />
                </div>                    
                }

                <div className="grid-colspan-3">
                    <div ref={viewerElementRef} style={{width:500, height: 400, display: pdbNotFound ? 'none': null}}></div>
                </div>
                
            </div>
            
            
        </div>
    );
}

export default PdbViewer
