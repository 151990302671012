import { useMemo } from "react";
import { useSubcellLoc } from "../hooks.js";
import { decoratedlist, HeaderWithModal } from "../utils.jsx";

const deeploc_threshold = {
    cytoplasm: '0.476',
    nucleus: '0.501',
    extracellular: '0.617',
    cell_membrane: '0.564',
    mitochondrion: '0.622',
    plastid: '0.640',
    endoplasmic_reticulum: '0.609',
    lysosome_vacuole: '0.585',
    golgi_apparatus: '0.649',
    perixosome: '0.736'
  };
  
  const SubLocdecoratedlist = (label, data, link=null, hover=null) =>
  <div className="flex">

      <div className="group relative inline-block m-1 w-1/6 pr-2 text-left border-r-2 border-slate-400 font-bold cursor-default">
          <div>{label}</div>

          {hover ? <div className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-400 text-white p-1 rounded absolute bottom-full mb-1 text-xs whitespace-nowrap">
          {hover}
          </div> : null}
      </div>

      <div className="m-auto ml-2">{data} {link}</div>

  </div>



const SubCellLoc = ({protein_seq_id}) => {


    const {isReady, SubcellLoc} = useSubcellLoc(protein_seq_id)
    const deeplocTable = (locs) => {
        return <table className="border-collapse border border-slate-400">
            <tr>
                <td className="px-2 text-xs"></td>
                <td className="px-2 text-xs">score (threshold)</td>
            </tr>
            {Object.entries(locs).map(([key, val]) => {
                const exclude = ['protein_seq_id', 'signals', 'detected_localisation']
                if (!(exclude.indexOf(key)>=0)){
                return (
                    <tr key={key}>
                        <td className="px-2 text-xs">{key}</td>
                        <td className="px-2 text-xs">{val.toFixed(3)} ({deeploc_threshold[key]})</td>
                    </tr>
                );}
            })}
        </table>
    }

    if(!isReady) {
        return <span>...loading</span>
    }

    if(!SubcellLoc) {
      return <div>no predicted localization for this sequence</div>
    }
console.log(SubcellLoc)
    return (
      <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
          <div className="border-b width-full border-slate-200">
            <HeaderWithModal title="Predicted Subcellular Localization by DeepLoc">
            <p>The subcellular localization of the protein was predicted using the DeepLoc 2.0 model. 
            <a className="cursor-pointer hover:underline text-blue-600" href='/documentation'> See the documentation</a> for more details on the method.</p>
            <p>
                Localization:
                <ul>
                <li className='ml-4'>-Localizations for which DeepLoc 2.0 predicts a score higher than the respective threshold. If none of the predictions met the required threshold, the localization with the score closest to its threshold is given.</li>
                </ul>
            </p>
            <p>Signals: 
            <ul>
                <li className='ml-4'>-Sorting signals predicted by DeepLoc 2.0.</li>
                </ul>
            </p>
            <p>Complete Deeploc 2.0 output: 
            <ul>
                <li className='ml-4'>-Prediction: Prediction score for each subcellular localization.</li>
                <li className='ml-4'>-Threshold(between parenthesis): Corresponding score threshold above which a prediction is deemed positive for each subcellular localization. The thresholds were provided by the developers and were calculated using Matthew’s Correlation Coefficient (MCC).</li>
                </ul>
            </p>
            
          </HeaderWithModal>
          </div>

        <div className="mt-4 ml-0">
            {SubLocdecoratedlist('Localization', SubcellLoc.detected_localisation)}
            {SubLocdecoratedlist('Signals', SubcellLoc.signals)}
        </div>
        <div className="mt-4 ml-0">
            <div>
            <p>
                Complete DeepLoc 2.0 output
            </p>
            {deeplocTable(SubcellLoc)}
            </div>
        </div>
      </div>
    );
}

export default SubCellLoc