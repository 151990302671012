import { useState, useEffect } from "react";
import {useLoaderData} from "react-router-dom";
import ScreenWithSidebar from "../components/ScreenWithSidebar.jsx";
import DomainsTile from "../components/DomainsTile.jsx";
import SlimsTile from "../components/ShortLinearMotifsTile.jsx";
import SpectrumViewer from "../components/SpectrumViewer.jsx";
import ConservationTile from "../components/ConservationTile.jsx";
import RiboseqTile from "../components/RiboseqTile.jsx";
import IdrViewer from "../components/IdrViewer.jsx";
import ProteinTranscriptsTile from "../components/ProteinTranscriptsTile.jsx";
import PdbViewer from "../components/PdbViewer.jsx";
import TrackViewer from "../components/TrackViewer.jsx";
import RelatedProteinsTile from "../components/RelatedProteinsTile.jsx";
import {useFetchProtDetails, useExtSources, useEvidenceSummary} from "../hooks.js";
import { decoratedlist } from "../utils.jsx";
import SubCellLoc from "../components/SubcellLocalisationTile.jsx";

const panelNames = [
    "Summary",
    "Mass Spectrometry",
    "Structure Prediction",
    "Transcripts",
    "Riboseq",
    "Protein Info",
    "Transcripts",
    "Conservation",
    "Domains"
]

export default ({}) => {



    const [species_sn, protein] = useLoaderData()

    const [trackViewerArgs, setTrackViewerArgs] = useState({isReady: false})

    const [visibleProtSeqInModal, setVisibleProtSeqInModal] = useState(null)

    const [ExtSourcesInModal, setExtSourcesInModal] = useState(null)

    const [displayExtSources, setDisplayExtSources] = useState(null)


    const [displayProtDetails, setDisplayProtDetails] = useState({
        coord: null,
        gene: null,
        accession:null,
        type:null,
        sequence:null
    })
    
    const {isReady, data:protDetails} = useFetchProtDetails({species_sn, protein_seq_id:protein.protein_seq_id})
    const {isReady_ExtS, Extsource} = useExtSources({species_sn, protein_seq_id:protein.protein_seq_id})   

    const {isReady:isReady_EvidenceSummary, evidence} = useEvidenceSummary({species_sn, protein_seq_id:protein.protein_seq_id})   
    console.log(evidence)

    const ExternalSourcesTable = (Extsource) => {
        const preprocessElement = (element) => {
            if (element && element.includes(',')) {
              return element.split(',');
            } else if (element) {
              return [element]; 
            }
            return null; 
          };
        const processedUorf = preprocessElement(Extsource.uorfdb_id);
        const processedSmprot = preprocessElement(Extsource.smprot_id);
        const processedSorf = preprocessElement(Extsource.sorf_id);
        const processedNuorf = preprocessElement(Extsource.nuorfdb_id);

        
        if (!processedUorf && !processedSmprot && !processedSorf && !processedNuorf)  {return 'None'}
        return (
            <>
                <tr>
                    {processedUorf && (
                    <td className="px-2 text-xs">uORFdb : {processedUorf.slice(0, 2)}</td>
                    )}
                </tr>
                <tr>
                    {processedSmprot && (
                    <td className="px-2 text-xs">SmProt : {processedSmprot.slice(0, 2).map((sorfElement, index) => (<><a key={index} className="cursor-pointer hover:underline text-blue-600" target="_blank" href={`http://bigdata.ibp.ac.cn/SmProt/SmProt.php?ID=${sorfElement}`}>
                        {sorfElement}
                    </a></>
                      ))}</td>
                    )}
                </tr>
                <tr>
                    {processedSorf && (
                    <td className="px-2 text-xs">sORFs : {processedSorf.slice(0, 2).join(' | ')}</td>
                    )}
                </tr>
                <tr>
                    {processedNuorf && (
                    <td className="px-2 text-xs">nuORFdb : {processedNuorf.slice(0, 2).join(' | ')}</td>
                    )}
                </tr>
            <tr>
              <td className="px-2 text-xs">
                <a
                  className={`font-medium text-blue-600 dark-text-blue-500 hover:underline cursor-pointer`}
                  onClick={() => setExtSourcesInModal(Extsource)}
                >
                  ...
                </a>
              </td>
            </tr>
          </>
        )
      }


    useEffect(() => {
        if(isReady) {
            if(protDetails.length > 0) {
                const [firstRow, ] = protDetails
                const genes = Array.from(new Set(protDetails.map(v => v.symbol).concat(protDetails.map(v => v.gene_accession))))
                const prot_acc = Array.from(new Set(protDetails.map(v => v.accession))).concat(Array.from(new Set(protDetails.map(v => v.uniprot_accessions))))
                console.log(protDetails)
                setTrackViewerArgs({
                    chrom: firstRow.location_chr,
                    regionStart: firstRow.location_start,
                    regionEnd: firstRow.location_end,
                    strand: firstRow.strand
                })
                setDisplayProtDetails({
                    gene:genes.join(', '),
                    accession: prot_acc.length > 2 ? prot_acc.join(', ') : prot_acc[0],
                    type:firstRow.type,
                    sequence_length_weight:`${firstRow.sequence.length} (${(firstRow.weight/1000).toFixed(2)}kDa)`,
                    coord:`chr${firstRow.location_chr}:${firstRow.location_start}-${firstRow.location_end}`,
                    sequence:firstRow.sequence
                })}}
        if(isReady_ExtS) {
            console.log(Extsource)
                if (Extsource){
                    setDisplayExtSources(ExternalSourcesTable(Extsource))
                    }
            }
    }, [isReady, isReady_ExtS])

    const [selectedPanelName, setSelectedPanelName] = useState(panelNames[0])

    const visiblePanel = () => {
        switch (true) {
            case selectedPanelName.includes("Summary"):
                return <>
                    <RelatedProteinsTile species_sn={species_sn} protein_seq_id={protein.protein_seq_id} />
                    <TrackViewer
                        argsReady={isReady}
                        chrom={trackViewerArgs.chrom}
                        regionStart={trackViewerArgs.regionStart}
                        regionEnd={trackViewerArgs.regionEnd + 10000}
                        strand={trackViewerArgs.strand}
                    />
                </>
            case selectedPanelName.includes("Domains"):
                return <DomainsTile protein_seq_id={protein.protein_seq_id}/>
            case selectedPanelName.includes("Mass Spectrometry"):
                return <SpectrumViewer species_sn={species_sn} protein_seq_id={protein.protein_seq_id}/>
            case selectedPanelName.includes("Conservation"):
                return <ConservationTile species_sn={species_sn} protein_seq_id={protein.protein_seq_id}/>
            case selectedPanelName.includes("Riboseq"):
                return <RiboseqTile species_sn={species_sn} protein_seq_id={protein.protein_seq_id}/>
            case selectedPanelName.includes("Disordered Regions"):
                return <>
                    <IdrViewer species_sn={species_sn} protein_seq_id={protein.protein_seq_id}/>
                    <SlimsTile protein_seq_id={protein.protein_seq_id}/>
                </>
            case selectedPanelName.includes("Structure"):
                return <PdbViewer protein_seq_id={protein.protein_seq_id}/>
            case selectedPanelName.includes("Localization"):
                return <SubCellLoc protein_seq_id={protein.protein_seq_id}/>
            case selectedPanelName.includes("Transcripts"):
                return <ProteinTranscriptsTile species_sn={species_sn} protein_seq_id={protein.protein_seq_id}/>
            default:
                return <div>
                    <h1>{selectedPanelName}</h1>
                </div>
        }
    }

    const PanelItem = ({label}) =>
            <li>
                <a href="#"
                   className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100"
                   onClick={() => setSelectedPanelName(label)}
                >
                    {label}
                </a>
            </li>

    const SidebarPanel = () =>
        <ul className="space-y-2 font-medium">
            <PanelItem label={"Summary"}/>
            <PanelItem label={"Transcripts"}/>
            <PanelItem label={`Mass Spectrometry (${evidence.ms_score})`} />
            <PanelItem label={`Domains (${evidence.domains_detected_count})`}/>
            <PanelItem label={"Conservation"}/>
            <PanelItem label={`Riboseq (${evidence.rs_score})`}/>
            <PanelItem label={"Disordered Regions"}/>
            <PanelItem label={"Structure"}/>
            <PanelItem label={"Localization"}/>
        </ul>

    const specieFullName = {
        'HS':"Homo sapiens",
        'PT':"Pan troglodytes",
        'RM':"Rattus norvegicus",
        'MM':"Mus musculus",
        'BT':"Bos taurus",
        'OA':"Ovis aries",
        'XT':"Xenopus tropicalis",
        'DR':"Danio rerio",
        'DM':"Drosophila melanogaster",
        'CE':"Caenorhabiditis elegans",
        'AT':"Arabidopsis thaliana",
        'SC':"Saccharomyces cerevisiae S288c"
    }
    const typeFullName = {'reference': 'RefProt', 'cryptic':'AltProt', 'isoform':'Novel Isoform'}

    const sequenceButton = (displayProtDetails) => <a
                className={`font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer`}
                onClick={() => setVisibleProtSeqInModal(displayProtDetails)}>aa sequence</a>
    
        
    return (
        <>

        <ScreenWithSidebar
            sidebarPanel={<SidebarPanel/>}
        >
            <div>
                <div className="text-xl font-bold">{displayProtDetails.accession}</div>
                <div className="columns-2">
                        {decoratedlist('Type', typeFullName[displayProtDetails.type])} 
                        {decoratedlist('Gene', displayProtDetails.gene)}
                        {decoratedlist('Organism', specieFullName[species_sn])}
                        {decoratedlist('Genomic Coordinates', displayProtDetails.coord)}
                        {decoratedlist('Amino Acids', displayProtDetails.sequence_length_weight, sequenceButton(displayProtDetails))}
                        {decoratedlist('External Sources', displayExtSources)}
                </div>
            </div>
            
        {visibleProtSeqInModal &&
            <ProteinSequenceModal
                displayProtDetails={displayProtDetails}
                onClose={() => setVisibleProtSeqInModal(null)}
                onCopy={() => navigator.clipboard.writeText(displayProtDetails.sequence)}
            />
        }
        {ExtSourcesInModal &&
            <ExtSourcesModal
                Extsource={Extsource}
                onClose={() => setExtSourcesInModal(null)}
            />
        }
                     
            {visiblePanel()}
        </ScreenWithSidebar>
        
        </>
    )

}

const ExtSourcesModal = ({Extsource, onClose}) => {
    const data = Object.keys(Extsource).reduce((acc, key) => {
        const values = Extsource[key].split(',');
        acc[key] = values;
        return acc;
      }, {});
    const maxLength = Math.max(
        data.uorfdb_id.length,
        data.smprot_id.length,
        data.sorf_id.length,
        data.nuorfdb_id.length
      );
    const url = {
        uorfdb_id: 'https://www.bioinformatics.uni-muenster.de/tools/uorfdb/',
        smprot_id: 'http://bigdata.ibp.ac.cn/SmProt/index.html',
        sorf_id: 'https://sorfs.ugent.be/',
        nuorfdb_id: 'https://www.nature.com/articles/s41587-021-01021-3',
      };
    const url_name={
        uorfdb_id: 'uORFdb',
        smprot_id: 'SmProt',
        sorf_id: 'sORFs',
        nuorfdb_id: 'nuORFdb'
    }
    const shouldDisplayColumn = key => data[key].some(value => value !== '')
    return <div className="fixed z-50 p-4 max-h-full">
        <div className="relative w-full max-w-full">
            <div className="relative bg-white rounded-lg shadow max-w-screen">
                <div className="flex items-center justify-between p-5 border-b rounded-t">
                    <h3 className="text-xl font-medium text-gray-900">
                        Corresponding accessions from external resources
                    </h3>
                    <button onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="extralarge-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="table-container" style={{maxHeight:700}}>
                <table>
                    <thead>
                        <tr>
                        {Object.keys(url).map(key => (
                            shouldDisplayColumn(key) && <th key={key}><a className="cursor-pointer hover:underline text-blue-600" href={url[key]} target="_blank">{url_name[key]}</a></th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                    {Array.from({ length: maxLength }, (_, index) => (
                        <tr key={index}>
                            {shouldDisplayColumn('uorfdb_id') && <td className='px-11'>{data.uorfdb_id[index]}</td>}
                            {shouldDisplayColumn('smprot_id') && <td className='px-11'><a className="cursor-pointer hover:underline text-blue-600" target='' href={`http://bigdata.ibp.ac.cn/SmProt/SmProt.php?ID=${data.smprot_id[index]}`}>{data.smprot_id[index]}</a></td>}
                            {shouldDisplayColumn('sorf_id') && <td className='px-11'>{data.sorf_id[index]}</td>}
                            {shouldDisplayColumn('nuorfdb_id') && <td className='px-11'>{data.nuorfdb_id[index]}</td>}
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        onClick={onClose}
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
}

const ProteinSequenceModal = ({displayProtDetails, onClose, onCopy}) => {
    return <div className="fixed z-50 p-4 max-h-full">
        <div className="relative w-full max-w-7xl">
            <div className="relative bg-white rounded-lg shadow max-w-screen">
                <div className="flex items-center justify-between p-5 border-b rounded-t">
                    <h3 className="text-xl font-medium text-gray-900">
                        {displayProtDetails.accession} amino acid sequence
                    </h3>
                    <button onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="extralarge-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="p-6 space-y-6 overflow-y-scroll" style={{maxHeight:700}}>
                  <p className={"prose md:prose-md break-words"}>{displayProtDetails.sequence}</p>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        onClick={onCopy}
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                        Copy
                    </button>
                    <button
                        onClick={onClose}
                        className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
}

