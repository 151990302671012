import SiteHeader from "../components/SiteHeader.jsx";
import SiteFooter from "../components/SiteFooter.jsx";

const decoratedLink = (uri, label) => {
    return <a href={uri} className="underline hover:font-semibold">{label}</a>
}

export default () => <div className="flex flex-col h-screen justify-between">
    <SiteHeader/>

    <div className="grid grid-row-3 place-content-center gap-16">

        <div className="text-center">
            <h1 className="text-3xl font-semibold mb-5 text-blue-800">The OpenProt Team</h1>
            <h3 className="text-base">OpenProt is a collaboration of the {decoratedLink("https://www.roucoulab.com/en/index.html", "Roucou")} and {decoratedLink("https://www.researchgate.net/lab/Marie-Brunet-Lab", "Brunet")} labs</h3>
            <h3>from Universite de Sherbrooke in Canada</h3>            
        </div>

        <div className="grid grid-cols-2 mb-2">
            <div className="text-center">
                <h3 className="font-semibold">Leadership</h3>
                <ul className="list-inside list-image-[url(img/envelope.png)]">
                    <li>{decoratedLink("mailto:xavier.roucou@usherbrooke.ca", "Xavier Roucou")}</li>
                    <li>{decoratedLink("mailto:marie.brunet@usherbrooke.ca", "Marie Brunet")}</li>
                </ul>
            </div>
            <div className="text-center">
                <h3 className="font-semibold">Informatics</h3>
                <ul className="list-inside list-image-[url(img/envelope.png)]">
                    <li>{decoratedLink("mailto:sebastien.leblanc5@usherbrooke.ca", "Sebastien Leblanc")}</li>
                    <li>{decoratedLink("mailto:feriel.yala@usherbrooke.ca", "Feriel Yala")}</li>
                    <li>{decoratedLink("mailto:nicolas.provencher@usherbrooke.ca", "Nicolas Provencher")}</li>
                    <li>{decoratedLink("mailto:jean-francois.lucier@usherbrooke.ca", "Jean-Francois Lucier")}</li>
                    <li>{decoratedLink("mailto:maxime.levesque@gmail.com", "Maxime Lévesque")}</li>
                </ul>
            </div>
        </div>
        <div className="text-center p-0.1 -mb-6">
            <h3 className="font-semibold" >Collaborators</h3>
        </div>
        <div className="grid grid-cols-2 -mt-6">
            <div className="text-center">
                <h3 className="font-semibold mb-3">Université de Sherbrooke, <br />Quebec, Canada</h3>
                <ul className="list-inside">
                    <li>Darel Hunting</li>
                    <li className="list-inside list-image-[url(img/leaving_site_ux1.png)]">{decoratedLink("https://bioinfo-scottgroup.med.usherbrooke.ca/team/", "Michelle Scott")}</li>
                    <li>François-Michel Boisvert</li>
                    <li className="list-inside list-image-[url(img/leaving_site_ux1.png)]">{decoratedLink("https://cobius.usherbrooke.ca/team/", "Aïda Ouangraoua")}</li>
                </ul>
            </div>
            <div className="text-center">
                <h3 className="font-semibold mb-3">University of Lille, <br /> France</h3>
                <ul>
                <li className="list-inside list-image-[url(img/leaving_site_ux1.png)]">{decoratedLink("https://www.laboratoire-prism.fr/index.php/about/members/18-fournier-isabelle", "Isabelle Fournier")}</li>
                <li className="list-inside list-image-[url(img/leaving_site_ux1.png)]">{decoratedLink("https://www.laboratoire-prism.fr/index.php/about/members/20-salzet-michel", "Michel Salzet")}</li>
                </ul>
            </div>
        </div>
    </div>
    <SiteFooter/>
</div>