import SiteHeader from "../components/SiteHeader.jsx";
import SiteFooter from "../components/SiteFooter.jsx";

const decoratedLink = (uri, label) => {
    return <a href={uri} className="underline hover:font-semibold">{label}</a>
}

export default () => <div className="flex flex-col h-screen justify-between">
    <SiteHeader/>

    <div className="grid grid-row-3 place-content-center gap-16">

        <div className="text-center">
            <h1 className="text-3xl font-semibold mb-5 text-blue-800">License & Disclaimer</h1>
            <h3 className="text-base">OpenProt is licensed under {decoratedLink("http://creativecommons.org/licenses/by/4.0/?ref=chooser-v1", "CC BY 4.0")}</h3>
            <h3>{decoratedLink("/publications", "How to cite OpenProt")}</h3>            
        </div>

        <div className="text-center">
        <h3 className="text-base">Disclaimer</h3>
        <h3 className="text-base">We do not guarantee the accuracy of the data and are not responsible for any damages that may result from its use.</h3>
        </div>
    </div>
    <SiteFooter/>
</div>