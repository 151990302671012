import { useMemo, useState, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper
} from 'react-table';
import Link from "../widgets/Link.jsx";
import {useFetchProteinTranscripts} from "../hooks.js";
import TranscriptExpression from "../components/TranscriptExpression.jsx";
import {  HeaderWithModal } from "../utils.jsx"

const columnHelper = createColumnHelper()


const TranscriptsTable = ({species_sn, protein_seq_id, onSelectedTranscript}) => {

    const {isReady, rows} = useFetchProteinTranscripts({
        species_sn,
        protein_seq_id
    })

    useEffect(() => {
        if(isReady) {
            for(let row of rows) {
                if(row.gtex_available) {
                    onSelectedTranscript(row)
                    return
                }
            }
        }
    },[`${isReady}`])

    const columns= useMemo(
        () => [
            columnHelper.accessor(
              row => row.annotation_source === "e" ? "Ensembl": "Refseq",
              {
                header: "annotation",
                cell: info => info.getValue()
              }
            ),
            columnHelper.accessor(
              row => row.accession,
              {
                header: "accession",
                cell: info => {
                  const t_acc = info.getValue()
                  if(species_sn != 'HS') {
                    return t_acc
                  }
                  if(t_acc.includes('ENST')) {
                    return <a className={"cursor-pointer text-blue-800"} href={`http://apr2022.archive.ensembl.org/Homo_sapiens/Gene/Summary?t=${t_acc}`} target="_blank">{t_acc}</a>
                  }
                  return <a className={"cursor-pointer text-blue-800"} href={`https://www.ncbi.nlm.nih.gov/nuccore/${t_acc}`} target="_blank">{t_acc}</a>
                }
              }
            ),
            columnHelper.accessor(
              row => row,
              {
                header: "tissue expression",
                cell: info => {
                    const row = info.getValue()
                    if(! row.gtex_available) {
                        return null
                    }
                    return <a className={"cursor-pointer text-blue-800"} onClick={() => onSelectedTranscript(row)}>
                        GTEx data
                    </a>
                }
              }
            ),
            columnHelper.accessor(
              row => row.type,
              {
                header: "type",
                cell: info => info.getValue()
              }
            ),
            columnHelper.accessor(
              row => row.frame,
              {
                header: "frame",
                cell: info => info.getValue()
              }
            ),
            columnHelper.accessor(
              row => row.strand,
              {
                header: "strand",
                cell: info => info.getValue()
              }
            ),
            columnHelper.accessor(
              row => row.as_kosak_motif === 1 ? "+": "-",
              {
                header: "kozak",
                cell: info => info.getValue()
              }
            ),
            columnHelper.accessor(
              row => row.high_eff_tis_motif === 1 ? "+": "-",
              {
                header: "high eff. TIS",
                cell: info => info.getValue()
              }
            )
        ],
        []
    )

    const table = useReactTable({
        data: rows,
        columns,
        getCoreRowModel: getCoreRowModel()
    })

    if(!isReady) {
        return <div>loading...</div>
    }

    return <table className="w-full text-sm text-left text-gray-500">
        <thead className={"text-xs text-gray-700 uppercase bg-gray-50"}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                      key={header.id}
                      className="border border-slate-300"
                      colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id}>
                    {
                        row.getVisibleCells().map(cell => {
                            const ctx = cell.getContext()
                            const v = ctx.getValue()
                            return <td
                                className="border border-slate-300"
                                key={cell.id}
                            >
                                {flexRender(cell.column.columnDef.cell, ctx)}
                            </td>
                        })
                    }
              </tr>
            )
          })}
        </tbody>
      </table>
}


export default ({species_sn, protein_seq_id}) => {

    const [selectedTranscript, setSelectedTranscript] = useState(null)

    return <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
          <div className="border-b width-full border-slate-200 mb-4">
            <HeaderWithModal title="Transcripts">
            <p>Table of all transcripts containing an ORF that encodes this protein.</p>
            <p><span className="font-bold">Annotation:</span> source annotation for the transcript</p>
            <p><span className="font-bold">Accession:</span> accession of the corresponding transcript</p>
            <p><span className="font-bold">Tissue expression:</span> click on the GTEx button of corresponding transcripts to see their expression profile in different tissues.</p>
            <p><span className="font-bold">Type:</span> biotype of the transcript</p>
            <p><span className="font-bold">Frame:</span> The reading frame for the three-nucleotide codons is determined with respect to the first nucleotide of each transcript (+1 reading frame). AltORFs completely nested within CDSs must be in a different reading frame than the CDSs. AltORFs present in UTRs or in ncRNAs may be in any of the 3 reading frames.</p>
            <p><span className="font-bold">Strand:</span> strand on the chromosome where transcript is transcribed from</p>
            <p><span className="font-bold">Kozak:</span> If the simplified Kozak sequence RNNATGG (where R = A or G) derived from Kozak M, 1981 and 2002 (PMID: 7301588, 12459250 ) is present (+) or not (-).</p>
            <p><span className="font-bold">High Eff. TIS:</span> If the high-efficiency TIS motif (RYMRMVAUGGC, where Y = U or C, M = A or C, R = A or G, and V = A, C, or G) derived from Noderer WL et al., 2014 (PMID: 25170020) is present (+) or not (-).</p>
            </HeaderWithModal>
          </div>
<div className="w-4/5">
            <TranscriptsTable species_sn={species_sn} protein_seq_id={protein_seq_id} onSelectedTranscript={setSelectedTranscript}/>
            {selectedTranscript && <TranscriptExpression transcript={selectedTranscript} />}            
</div>
    </div>
}
