import {useFetchRelatedProteins} from "../hooks.js";
import { HeaderWithModal } from "../utils.jsx"

export default ({species_sn, protein_seq_id}) => {
    const {isReady, relatedProts} = useFetchRelatedProteins({species_sn, protein_seq_id})

    const linkClass = (protacc) => {
        if (protacc.includes("IP_")) {
            return "text-[#CB3D58] hover:underline font-semibold"
        }
        else if (protacc.includes("II_")) {
            return "text-[#E0B64C] hover:underline font-semibold"
        }
        else {
            return "text-[#3C47C7] hover:underline font-semibold"
        }
    }

    const relatedProtItems = (protlist) => protlist.map((protItem, idx) => 
        <li>
            <a className={linkClass(protItem[1])} href={`https://openprot.org/proteins/${species_sn}/${protItem[0]}`} target="_blank">{protItem[1]}</a> 
            <span className="text-xs align-[2px]"> ({protItem[2]}|{protItem[3]})</span>
        </li>
    );

    if(!isReady) {
        return <span>...loading</span>
    }

    return <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)] mt-3 mr-3 rounded p-3">
        <div className="border-b width-full border-slate-200">
        <HeaderWithModal title="Related Proteins">
            <p>These a lists showing all proteins encoded by the same transcript or by the same gene, but on a different transcript for this protein.</p>
            <p>Details on the level of evidence available for each protein are indicated.</p>
            </HeaderWithModal>
        </div>
        
        <div className="grid grid-cols-2 gap-5">
            <div>
                <p className="">proteins encoded in the same transcript </p>
                <span className="text-xs mb-1">protein_accession (ms score | riboseq score)</span>
                <ul className="overflow-y-scroll h-32">
                    {relatedProtItems(relatedProts.related_transcripts)}
                </ul>    
            </div>
            <div>
                <p className="">proteins encoded in the same gene (different transcript)</p>
                <span className="text-xs mb-1">protein_accession (ms score | riboseq score)</span>
                <ul className="overflow-y-scroll h-32">
                    {relatedProtItems(relatedProts.related_genes)}
                </ul>    
            </div>
        </div>
    </div>
}